<template>
  <div class="g-box g-user-add-box" v-loading="loading">
    <div class="m-form-inline-mn" data-m="add">
      <el-form
        :model="ruleForm"
        class="demo-form-inline"
        size="small"
        :rules="rules"
        ref="ruleForm"
        label-width="180px"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <h2 class="m-tt">基本信息</h2>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="15">
            <el-form-item label="原密码" prop="pwd">
              <el-input
                type="password"
                v-model="ruleForm.pwd"
                placeholder="请输入原密码"
              ></el-input>
              <!-- placeholder="请输入原密码/如忘记原密码请点击下方的重置密码" -->
            </el-form-item>
            <el-form-item label="新密码" prop="newPwd">
              <el-input
                type="password"
                v-model="ruleForm.newPwd"
                placeholder="请输入新密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPwd">
              <el-input
                type="password"
                v-model="ruleForm.confirmPwd"
                placeholder="请再次输入新密码"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="m-btn-group">
      <!-- <el-button
        class="u-btn s-submit" size="small" type="primary"
        @click="resetPwd"
      >
        重置密码
      </el-button> -->
      <el-button
        class="u-btn s-submit"
        size="small"
        type="primary"
        @click="submitForm('ruleForm')"
      >
        提交
      </el-button>
      <el-button
        class="u-btn s-cancel"
        size="small"
        @click="returnPreviousPage"
      >
        返回
      </el-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import api from './../../../api'
import md5 from 'js-md5'

export default Vue.extend({
  name: 'user-add',
  components: {
  },
  directives: {},
  filters: {},
  props: {},
  data () {
    return {
      ruleForm: {
        userRole: 'TENANTUSER',
        role: '',
        tenant: '',
        eMail: '',
        pwd: '',
        confirmPwd: '',
        img: '',
        name: '',
        type: 'logistics',
        contactPerson: '',
        phone: '',
        state: 'online',
        isBankUser: true,
        org: []
      },
      filter: {
        userRoleOptions: [{
          label: '普通用户',
          value: 'USER'
        }, {
          label: '租户管理员',
          value: 'TENANTUSER'
        }, {
          label: '超级管理员',
          value: 'BANKUSER'
        }],
        tenantOptions: [],
        roleOptions: [],
        orgOptions: [],
        stateOptions: [{
          label: '启用',
          value: 'online'
        }, {
          label: '停用',
          value: 'offline'
        }]
      },
      loading: false,
      rules: {
        pwd: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          { pattern: /^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/, message: '最少8位，包括至少1个大写字母，1个小写字母，1个数字', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ],
        newPwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { pattern: /^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/, message: '最少8位，包括至少1个大写字母，1个小写字母，1个数字', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ],
        confirmPwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { pattern: /^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/, message: '最少8位，包括至少1个大写字母，1个小写字母，1个数字', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ]
      }
    }
  },
  computed: {
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.init()
  },
  beforeMount () {
  },
  mounted () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    init () {
    },
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.ruleForm.newPwd !== this.ruleForm.confirmPwd) {
            return this.$message.warning('密码不一致')
          }
          api.auth.postUpdatePassWordById({
            id: this.$route.params.id || '',
            oldPassword: md5(this.ruleForm.pwd),
            password: md5(this.ruleForm.newPwd)
          }).then(() => {

            this.$router.go(-1)
          })
        } else {
          return false
        }
      })
    },
    resetPwd () {
      api.auth.postResetPassword({
        id: this.$route.params.id || ''
      }).then(res => {

        this.$alert('密码已重置为 ' + res.data.password, '新密码', {
          confirmButtonText: '确定',
          callback: action => {
            this.$router.go(-1)
          }
        })
      })
    }
  }
})
</script>

<style lang="scss" scoped>
@import "./../../../assets/css/global-variable.scss";
.g-user-add-box {
  position: relative;
  overflow: auto;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
}
.m-tt {
  margin-bottom: 30px;
  font-size: $fs-title;
  color: $fc-primary;
  font-weight: 500;
  line-height: 18px;
  text-indent: 10px;
  border-left: 4px solid $fc-theme;
  &.s-no-top {
    margin-top: 30px;
  }
}
.m-crop {
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  cursor: pointer;
  &[readonly] {
    pointer-events: none;
  }
  .u-img {
    width: 100%;
    height: 100%;
  }
  .u-icon {
    font-size: 24px;
    color: $fc-secondary;
  }
}
</style>

<style>
</style>
